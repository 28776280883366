import React from "react"

import styles from "./content.module.scss"
import { AccountGuard } from "../../../../guards/account.guard"

const ContentPage3 = () => {
  return (
    <AccountGuard>
      <div className={`${styles.header}`}>
        <h2>Technical Official Introductory</h2>
      </div>

      <div className={`${styles.wrapper}`}>
        <div className={`${styles.sectionHeader}`}>
          <h3>Section 03</h3>
          <h1>International Functional Fitness Federation</h1>
        </div>
      </div>

      <div className={`${styles.contentWrapper}`}>
        <ul className={`${styles.content}`}>
          <li>
            <p>
              <i>
                Sports officials must be able to bring control to chaos,
                understand fairness, promote safety and encourage good
                sportsmanship. A sports official must have the positive
                characteristics of a police officer, lawyer, judge, accountant,
                reporter, athlete and diplomat. They are also someone who can be
                put in a position of authority and handle the responsibility
                without being overbearing. Along with the rules of the game you
                must fully understand your role before, during and after the
                game has ended.
              </i>
            </p>

            <p>
              <i>(Sport New Zealand, 2017)</i>
            </p>

            <p>
              A Functional Fitness Technical Official’s responsibility requires
              a proper balance between science and art. The science of this
              vocation is defined through the required movement standards and
              rules; these can be processed and understood with enough study and
              preparation. The art resides in the application of the memorized
              facts at full speed.
            </p>

            <p>
              The art, as well as the science, both require ample practice and a
              level of dedication to one’s craft. The International Functional
              Fitness Federation, as the governing body of the sport of
              functional fitness believes that a professional and proficient
              team of officials is a fundamental pillar in building sport
              acceptance among Olympic recognized sports.
            </p>

            <p>
              Fundamental to a Technical Official’s professionalism is
              understanding appropriate expectations prior to, during, and
              following an event. Some of these are restrictions to an
              individual’s behavior in relation to teams and athletes, fellow
              Technical Officials, event staff and the general public. As part
              of the innate responsibility of being a sport official, certified
              Technical Officials are expected to adhere to the rules outlined
              throughout this manual. Failure to adhere may include revocation
              of Technical Official certification and / or demotion in defined
              Technical Official position.
            </p>
          </li>
        </ul>

        <div className={`${styles.buttons}`}>
          <div className={`${styles.previous}`}>
            <a href="/courses/toi/content/2">
              <i
                className={`fas fa-arrow-alt-circle-left uk-margin-small-right`}
              />
              Previous
            </a>
          </div>
          <div className={`${styles.next}`}>
            <a href="/courses/toi/content/4">
              Next
              <i
                className={`fas fa-arrow-alt-circle-right uk-margin-small-left`}
              />
            </a>
          </div>
        </div>
      </div>
    </AccountGuard>
  )
}

export default ContentPage3
