import { useContext } from "react"
import { AppContext } from "../contexts/app.context"
import { isAdmin } from "../utils/user"
import { navigate } from "@reach/router"

export const AccountGuard = ({ children }) => {
  const { user } = useContext(AppContext)

  if (!isAdmin(user)) {
    navigate("/dashboard")

    return null
  }

  return children
}
